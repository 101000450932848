import { numericUtil, timeUtil } from '@cmg/common';
import { Tooltip, Typography } from '@cmg/design-system';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import React from 'react';

import PerformancePercentsWithTooltip from '../../../../../../common/components/format/performance-percents-with-tooltip/PerformancePercentsWithTooltip';
import { OfferingType, TermType } from '../../../../../../graphql/__generated__';
import { isInternationalOfferingsOn } from '../../../../../datalab/model/utils';
import BooleanIcon from '../../../../../shared/components/boolean-icon/BooleanIcon';
import {
  emptyValueTypography,
  getCompanyRepurchase,
  getCurrencyRangeFormat,
  getFormattedCurrency,
  getFormattedCurrencyInMillion,
  getFormattedPercentageValue,
  getOfferPriceRange,
  getStyledFormattedPercentageRange,
  isFOOffering,
} from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import OvltAuthTooltip from '../components/OvltAuthTooltip';
import {
  CardRowItem,
  emptyValue,
  StructurePricingRowLabels,
  StructurePricingSectionData,
  StructurePricingTableItem,
  StructurePricingTermName,
} from '../types';

export const getStructurePricingTableData = (
  offeringProfile: OfferingProfile_ListQuery
): StructurePricingTableItem[] => {
  const offering = offeringProfile.offeringById;
  const { attributes, pricingCurrency, terms, initialTerm, finalTerm, price } = offering ?? {};
  const {
    initialSizeInSecuritiesBase,
    overAllotmentExercised,
    overAllotmentExercisedPrimary,
    overAllotmentExercisedSecondary,
    latestGrossProceedsBase,
    latestSizeInSecuritiesBase,
    latestGrossProceedsTotal,
    latestSizeInSecuritiesTotal,
    initialGrossProceedsBase,
    revisedGrossProceedsBase,
    latestSizeInSecuritiesTotalPrimary,
    latestSizeInSecuritiesTotalSecondary,
  } = attributes ?? {};
  const revisedTerms: StructurePricingTableItem[] =
    terms
      ?.filter(term => term.type === TermType.Revised)
      .map(term => ({
        name: StructurePricingTermName.Revised,
        grossProceeds: getFormattedCurrencyInMillion(pricingCurrency, revisedGrossProceedsBase),
        offerPrice: getOfferPriceRange(offeringProfile, term),
        sizeInSecurities: numericUtil.getDisplayValueForInteger(term.sizeInSecuritiesBase),
        primary: numericUtil.getDisplayValueForInteger(term.sizeInSecuritiesBasePrimary),
        secondary: numericUtil.getDisplayValueForInteger(term.sizeInSecuritiesBaseSecondary),
        ovltAuth: <OvltAuthTooltip term={term} /> ?? emptyValue,
      })) ?? [];
  return [
    {
      name: StructurePricingTermName.Initial,
      grossProceeds: getFormattedCurrencyInMillion(pricingCurrency, initialGrossProceedsBase),
      offerPrice: getOfferPriceRange(offeringProfile, initialTerm),
      sizeInSecurities: numericUtil.getDisplayValueForInteger(initialSizeInSecuritiesBase),
      primary: numericUtil.getDisplayValueForInteger(initialTerm?.sizeInSecuritiesBasePrimary),
      secondary: numericUtil.getDisplayValueForInteger(initialTerm?.sizeInSecuritiesBaseSecondary),
      ovltAuth: <OvltAuthTooltip term={initialTerm} />,
    },
    ...revisedTerms,
    {
      name: StructurePricingTermName.Priced,
      grossProceeds: finalTerm
        ? getFormattedCurrencyInMillion(pricingCurrency, latestGrossProceedsBase)
        : emptyValue,
      offerPrice: finalTerm ? getFormattedCurrency(pricingCurrency, price) : emptyValue,
      sizeInSecurities: finalTerm
        ? numericUtil.getDisplayValueForInteger(latestSizeInSecuritiesBase)
        : emptyValue,
      primary: numericUtil.getDisplayValueForInteger(finalTerm?.sizeInSecuritiesBasePrimary),
      secondary: numericUtil.getDisplayValueForInteger(finalTerm?.sizeInSecuritiesBaseSecondary),
      ovltAuth: <OvltAuthTooltip term={finalTerm} />,
    },
    {
      name: StructurePricingTermName.OvltExercised,
      grossProceeds: emptyValue, // getFormattedCurrencyInMillion(pricingCurrency, ), field not available yet
      offerPrice: isNil(overAllotmentExercised)
        ? emptyValue
        : getFormattedCurrency(pricingCurrency, price),
      sizeInSecurities: numericUtil.getDisplayValueForInteger(overAllotmentExercised),
      primary: numericUtil.getDisplayValueForInteger(overAllotmentExercisedPrimary),
      secondary: numericUtil.getDisplayValueForInteger(overAllotmentExercisedSecondary),
      ovltAuth: '',
    },
    {
      name: StructurePricingTermName.Total,
      grossProceeds: getFormattedCurrencyInMillion(pricingCurrency, latestGrossProceedsTotal),
      offerPrice: getFormattedCurrency(pricingCurrency, price),
      sizeInSecurities: numericUtil.getDisplayValueForInteger(latestSizeInSecuritiesTotal),
      primary: numericUtil.getDisplayValueForInteger(latestSizeInSecuritiesTotalPrimary),
      secondary: numericUtil.getDisplayValueForInteger(latestSizeInSecuritiesTotalSecondary),
      ovltAuth: '',
    },
  ];
};

export const getFOStructurePricingData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { attributes, pricingCurrency, lastTradeBeforeFiling, type, price } =
    offeringProfile.offeringById ?? {};
  const {
    pctFileToOffer,
    lastTradeBeforeLaunch,
    pctLastTradeBeforeLaunch,
    lastTradeBeforeOffer,
    pctToLastTrade,
    vwapPrice,
    pctToVwap,
    fiftyTwoWeekHigh,
    pctTo52WeekHigh,
    fiftyTwoWeekHighDate,
    reOfferLow,
    reOfferHigh,
    pctReOfferLow,
    pctReOfferHigh,
    splitAdjustedOfferPrice,
    netPrice,
    allInCost,
    pctAllInCost,
    pctGrossSpread,
  } = attributes ?? {};
  const precision = 2;
  const showInternational = isInternationalOfferingsOn();
  const rows = isFOOffering(type)
    ? [
        {
          name: StructurePricingRowLabels.lastTradeBeforeFiling,
          value: getFormattedCurrency(pricingCurrency, lastTradeBeforeFiling),
          value2: (
            <PerformancePercentsWithTooltip
              value={pctFileToOffer}
              precision={precision}
              nullValue={emptyValue}
            />
          ),
        },
        {
          name: StructurePricingRowLabels.lastTradeBeforeLaunch,
          value: getFormattedCurrency(pricingCurrency, lastTradeBeforeLaunch),
          value2: (
            <PerformancePercentsWithTooltip
              value={pctLastTradeBeforeLaunch}
              precision={precision}
              nullValue={emptyValue}
            />
          ),
        },
        {
          name: StructurePricingRowLabels.lastTradeBeforeOffer,
          value: getFormattedCurrency(pricingCurrency, lastTradeBeforeOffer),
          value2: (
            <PerformancePercentsWithTooltip
              value={pctToLastTrade}
              precision={precision}
              nullValue={emptyValue}
            />
          ),
        },
        {
          name: StructurePricingRowLabels.VWAPBeforeOffer,
          value: getFormattedCurrency(pricingCurrency, vwapPrice),
          value2: (
            <PerformancePercentsWithTooltip
              value={pctToVwap}
              precision={precision}
              nullValue={emptyValue}
            />
          ),
        },
        {
          name: StructurePricingRowLabels.fiftyTwoWeekHigh,
          value: fiftyTwoWeekHigh ? (
            <Tooltip
              title={
                <Typography variant="tooltip" width={theme => theme.spacing(24.625)}>
                  {`52 Week High Date: ${timeUtil.formatAsMonthDateYear(
                    fiftyTwoWeekHighDate ?? ''
                  )}`}
                </Typography>
              }
              placement="top-end"
              variant="info"
            >
              <Typography variant="hint">
                {getFormattedCurrency(pricingCurrency, fiftyTwoWeekHigh)}
              </Typography>
            </Tooltip>
          ) : (
            emptyValue
          ),
          value2: (
            <PerformancePercentsWithTooltip
              value={pctTo52WeekHigh}
              precision={precision}
              nullValue={emptyValue}
              tooltip={`52 Week High Date: ${timeUtil.formatAsMonthDateYear(
                fiftyTwoWeekHighDate ?? ''
              )}`}
              tooltipVariant="info"
            />
          ),
        },
        {
          name: StructurePricingRowLabels.REOffer,
          value:
            isNumber(reOfferLow) && isNumber(reOfferHigh) && pricingCurrency
              ? getCurrencyRangeFormat({
                  valueLow: reOfferLow,
                  valueHigh: reOfferHigh,
                  pricingCurrencyCode: pricingCurrency,
                  showInternational,
                })
              : emptyValue,
          value2: getStyledFormattedPercentageRange(pctReOfferLow, pctReOfferHigh) ?? emptyValue,
        },
        {
          name: StructurePricingRowLabels.splitAdjustedOfferPrice,
          value: getFormattedCurrency(pricingCurrency, splitAdjustedOfferPrice),
          value2: '', // always null
          isHidden: isNil(splitAdjustedOfferPrice) || splitAdjustedOfferPrice === price,
        },
        {
          name: StructurePricingRowLabels.netPrice,
          value: getFormattedCurrency(pricingCurrency, netPrice),
          value2: (
            <PerformancePercentsWithTooltip
              value={pctGrossSpread}
              precision={precision}
              nullValue={emptyValue}
            />
          ),
        },
        {
          name: StructurePricingRowLabels.allInCost,
          value: getFormattedCurrency(pricingCurrency, allInCost),
          value2: (
            <PerformancePercentsWithTooltip
              value={pctAllInCost}
              precision={precision}
              nullValue={emptyValue}
            />
          ),
        },
      ]
    : [];
  return rows.filter(row => !row.isHidden);
};

export const getStructurePricingRowsCol1Data = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { attributes, pricingCurrency } = offeringProfile.offeringById ?? {};
  const {
    preOfferingShares,
    latestPostOfferingShares,
    marketCapPreOffering,
    marketCapAtPricing,
    pctMarketCapPreOffering,
    pctMarketCapAtPricing,
    latestPctSecondaryShares,
  } = attributes ?? {};
  const rows = [
    {
      name: StructurePricingRowLabels.sharesOutstandingPre,
      value: isNumber(preOfferingShares)
        ? numericUtil.getDisplayValueForInteger(preOfferingShares)
        : emptyValueTypography,
    },
    {
      name: StructurePricingRowLabels.sharesOutstandingPost,
      value: isNumber(latestPostOfferingShares)
        ? numericUtil.getDisplayValueForInteger(latestPostOfferingShares)
        : emptyValueTypography,
    },
    {
      name: StructurePricingRowLabels.marketCapPre,
      value: isNumber(marketCapPreOffering)
        ? getFormattedCurrencyInMillion(pricingCurrency, marketCapPreOffering)
        : emptyValueTypography,
    },
    {
      name: StructurePricingRowLabels.marketCapPost,
      value: isNumber(marketCapAtPricing)
        ? getFormattedCurrencyInMillion(pricingCurrency, marketCapAtPricing)
        : emptyValueTypography,
    },
    {
      name: StructurePricingRowLabels.marketCapPrePostPct,
      value:
        isNumber(pctMarketCapPreOffering) && isNumber(pctMarketCapAtPricing)
          ? `${getFormattedPercentageValue(pctMarketCapPreOffering)}/${getFormattedPercentageValue(
              pctMarketCapAtPricing
            )}`
          : emptyValueTypography,
    },
    // Cornerstone fields not yet available
    // {
    //   name: StructurePricingRowLabels.marketCapPrePctNoCornerstone,
    //   value: emptyValueTypography ?? emptyValueTypography,
    //   isHidden: fieldName === null || fieldName === undefined,
    // },
    // {
    //   name: StructurePricingRowLabels.pctCornerstone,
    //   value: emptyValueTypography ?? emptyValueTypography,
    //   isHidden: fieldName === null || fieldName === undefined,
    // },
    {
      name: StructurePricingRowLabels.pctSecondary,
      value: isNumber(latestPctSecondaryShares)
        ? getFormattedPercentageValue(latestPctSecondaryShares)
        : emptyValueTypography,
    },
  ];
  return rows;
};

export const getStructurePricingRowsCol2Section1Data = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { type, attributes, pricingCurrency, price } = offeringProfile.offeringById ?? {};
  const {
    splitAdjustedOfferPrice,
    pctVsMidpoint,
    pctChangeInSize,
    priceVsRangeDisplayName,
    preOfferingAdtv,
    adtvRangeLowDate,
    adtvRangeHighDate,
    sizeAsMultipleOfAdtv,
  } = attributes ?? {};
  const rows = [
    {
      name: StructurePricingRowLabels.splitAdjustedOfferPrice,
      value: getFormattedCurrency(pricingCurrency, splitAdjustedOfferPrice),
      // FollowOn offerings have the split displayed in the discount table
      isHidden:
        isFOOffering(type) || isNil(splitAdjustedOfferPrice) || splitAdjustedOfferPrice === price,
    },
    {
      name: StructurePricingRowLabels.pctToMidpoint,
      value: isNumber(pctVsMidpoint)
        ? getFormattedPercentageValue(pctVsMidpoint)
        : emptyValueTypography,
      isHidden: type !== OfferingType.Ipo,
    },
    {
      name: StructurePricingRowLabels.changeInBaseProceedsPct,
      value: isNumber(pctChangeInSize)
        ? getFormattedPercentageValue(pctChangeInSize)
        : emptyValueTypography,
      isHidden: type !== OfferingType.Ipo,
    },
    {
      name: StructurePricingRowLabels.priceVsRange,
      value: priceVsRangeDisplayName ?? emptyValueTypography,
      isHidden: type !== OfferingType.Ipo,
    },
    {
      name: StructurePricingRowLabels.thirtyDayADTV,
      value: isNumber(preOfferingAdtv)
        ? numericUtil.getDisplayValueForInteger(preOfferingAdtv)
        : emptyValueTypography,
      isHidden: !isFOOffering(type),
    },
    {
      name: StructurePricingRowLabels.ADTVDateRange,
      value:
        adtvRangeLowDate && adtvRangeHighDate
          ? `${timeUtil.formatAsMonthDateYear(
              adtvRangeLowDate
            )}\u2013${timeUtil.formatAsMonthDateYear(adtvRangeHighDate)}`
          : emptyValueTypography,
      isHidden: !isFOOffering(type),
    },
    {
      name: StructurePricingRowLabels.sizeAsMultipleOfADTV,
      value: isNumber(sizeAsMultipleOfAdtv)
        ? `${numericUtil.getDisplayValueForNumber(sizeAsMultipleOfAdtv, 1)}x`
        : emptyValueTypography,
      isHidden: !isFOOffering(type),
    },
  ];
  return rows.filter(row => !row.isHidden);
};
export const getStructurePricingRowsCol2Section2Data = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { attributes, type } = offeringProfile.offeringById ?? {};
  const { isCarveOut, isCompanyRepurchaseIncluded, isCompanyRepurchaseAdditional, isCleanUpTrade } =
    attributes ?? {};
  const rows = [
    {
      name: StructurePricingRowLabels.carveOut,
      value: <BooleanIcon value={isCarveOut} />,
    },
    {
      name: StructurePricingRowLabels.cleanUpTrade,
      value: <BooleanIcon value={isCleanUpTrade} />,
      isHidden: !isFOOffering(type),
    },
    {
      name: StructurePricingRowLabels.companyRepurchase,
      value: getCompanyRepurchase(isCompanyRepurchaseIncluded, isCompanyRepurchaseAdditional),
    },
  ];
  return rows.filter(row => !row.isHidden);
};

export const getStructurePricingRowsCol3Data = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { type, attributes, useOfProceedsDisplayNames, hasForwardAgreement } =
    offeringProfile.offeringById ?? {};
  const { isFirstFollowOn, isSyntheticSecondary, isConcurrentOffering, useOfProceedsNote } =
    attributes ?? {};
  const rows = [
    {
      name: StructurePricingRowLabels.concurrentOffering,
      value: <BooleanIcon value={isConcurrentOffering} />,
    },
    {
      name: StructurePricingRowLabels.firstFO,
      value: <BooleanIcon value={isFirstFollowOn} />,
      isHidden: !isFOOffering(type),
    },
    {
      name: StructurePricingRowLabels.forwardAgreement,
      value: <BooleanIcon value={hasForwardAgreement} />,
    },
    {
      name: StructurePricingRowLabels.syntheticSecondary,
      value: <BooleanIcon value={isSyntheticSecondary} />,
    },
    {
      name: StructurePricingRowLabels.useOfProceeds,
      value:
        useOfProceedsDisplayNames && useOfProceedsDisplayNames.length > 0
          ? useOfProceedsDisplayNames.join(', ')
          : emptyValueTypography,
    },
    {
      name: StructurePricingRowLabels.useOfProceedsNotes,
      value: useOfProceedsNote ? (
        <Tooltip
          title={<Typography>{useOfProceedsNote}</Typography>}
          placement="top-start"
          variant="info"
        >
          <Typography
            variant="hint"
            noWrap
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth={theme => theme.spacing(21)}
          >
            {useOfProceedsNote}
          </Typography>
        </Tooltip>
      ) : (
        emptyValueTypography
      ),
    },
  ];
  return rows.filter(row => !row.isHidden);
};
const getStructurePricingSectionColumns = (
  matchesMediumDown: boolean,
  matchesSmallDown: boolean,
  structurePricingCol1: CardRowItem[],
  structurePricingCol2Sect1: CardRowItem[],
  structurePricingCol2Sect2: CardRowItem[],
  structurePricingCol3: CardRowItem[]
): {
  column1: CardRowItem[];
  column2: CardRowItem[];
  column3: CardRowItem[];
} => {
  if (matchesSmallDown) {
    // 1 column
    return {
      column1: [
        ...structurePricingCol1,
        ...structurePricingCol2Sect1,
        ...structurePricingCol2Sect2,
        ...structurePricingCol3,
      ],
      column2: [],
      column3: [],
    };
  }
  if (matchesMediumDown) {
    // 2 columns
    return {
      column1: [...structurePricingCol1, ...structurePricingCol2Sect1],
      column2: [...structurePricingCol2Sect2, ...structurePricingCol3],
      column3: [],
    };
  }
  return {
    // 3 columns
    column1: [...structurePricingCol1],
    column2: [...structurePricingCol2Sect1, ...structurePricingCol2Sect2],
    column3: structurePricingCol3,
  };
};

export const useGetStructurePricingSectionData = (
  offeringProfile: OfferingProfile_ListQuery,
  matchesMediumDown: boolean,
  matchesSmallDown: boolean
): StructurePricingSectionData =>
  React.useMemo(() => {
    const structurePricingTable = getStructurePricingTableData(offeringProfile);
    const structurePricingFOData = getFOStructurePricingData(offeringProfile);
    const structurePricingCol1 = getStructurePricingRowsCol1Data(offeringProfile);
    const structurePricingCol2Sect1 = getStructurePricingRowsCol2Section1Data(offeringProfile);
    const structurePricingCol2Sect2 = getStructurePricingRowsCol2Section2Data(offeringProfile);
    const structurePricingCol3 = getStructurePricingRowsCol3Data(offeringProfile);

    return {
      structurePricingTable,
      structurePricingFOData,
      structurePricingRows: getStructurePricingSectionColumns(
        matchesMediumDown,
        matchesSmallDown,
        structurePricingCol1,
        structurePricingCol2Sect1,
        structurePricingCol2Sect2,
        structurePricingCol3
      ),
    };
  }, [matchesMediumDown, matchesSmallDown, offeringProfile]);
